var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuFormTree" },
    [
      _c("form-panel", [
        _vm.menuGroupData && _vm.menuGroupData.length
          ? _c(
              "div",
              { staticClass: "groups" },
              [
                _vm._l(_vm.menuGroupData, function (list, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "group" },
                    [
                      list && list.length
                        ? _c("menu-form-tree-nested", {
                            attrs: {
                              setTempData: _vm.setTempData,
                              resetData: _vm.resetData,
                              setActiveMenuId: _vm.setActiveMenuId,
                              activeMenuId: _vm.activeMenuId,
                              menuId: list[0].pId,
                              list: list,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "border" },
                  _vm._l(_vm.titles, function (title, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "border-item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(title)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              2
            )
          : _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v("暂无可编辑菜单"),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }