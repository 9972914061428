<template>
  <div class="MenuFormTree">
    <form-panel>
      <div class="groups" v-if="menuGroupData && menuGroupData.length">
        <div
          class="group"
          v-for="(list, index) in menuGroupData"
          :key="index"
        >
          <menu-form-tree-nested
            v-if="list && list.length"
            :setTempData="setTempData"
            :resetData="resetData"
            :setActiveMenuId="setActiveMenuId"
            :activeMenuId="activeMenuId"
            :menuId="list[0].pId"
            :list="list"
          />
        </div>
        <div class="border">
          <div class="border-item" v-for="(title, index) in titles" :key="index">
            <div class="title">{{ title }}</div>
          </div>
        </div>
      </div>
      <div v-else style="text-align:center;">暂无可编辑菜单</div>
    </form-panel>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MenuFormTreeNested from './MenuFormTreeNested'
import { getMenuResourceURL, menuMoveURL } from './api'
import { cloneDeep } from 'lodash'

export default {
  name: 'MenuFormTree',
  components: {
    Draggable,
    MenuFormTreeNested
  },
  data () {
    return {
      titles: [
        '顶级菜单',
        '一级菜单',
        '二级菜单',
        '三级菜单',
        '四级菜单'
      ],
      activeMenuId: undefined,
      menuGroupData: [],
      tempData: []
    }
  },
  created () {
    this.getMenuResource()
  },
  mounted () {
    this.$setBreadcrumb('编辑菜单层级')
  },
  methods: {
    setActiveMenuId (menuId) {
      this.activeMenuId = menuId
    },
    setTempData () {
      this.tempData = cloneDeep(this.menuGroupData)
    },
    resetData () {
      this.menuGroupData = this.tempData
    },
    initData (list) {
      if (list && list.length) {
        list.forEach(item => {
          item['$status'] = 0
          this.initData(item.childMenus)
        })
      }
    },
    setGroup (list) {
      const group = []
      if (list && list.length) {
        list.forEach(item => {
          group.push([item])
        })
      }
      return group
    },
    async getMenuResource () {
      const params = {
        regionId: this.$route.query.tenantId
      }
      console.log(params,'0000')
      const { status, data } = await this.$axios.get(getMenuResourceURL,{ params })
      if (status === 100) {
        this.initData(data)
        this.menuGroupData = this.setGroup(data)
      }
    }
  }
}
</script>

<style lang="scss">
.loading-menu-form-tree {
  .el-loading-spinner .path {
    stroke: white;
  }
  .el-loading-text {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
.MenuFormTree {
  .groups {
    position: relative;
    width: 1060px;
    padding-top: 43px;
    margin-right: 20px;
    .border {
      width: 100%;
      height: calc(100% - 2px);
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #999;
      border-right: none;
      display: flex;
      justify-content: space-between;
      .border-item {
        width: 100%;
        border-right: 1px solid #999;
        .title {
          text-align: center;
          height: 43px;
          line-height: 43px;
          border-bottom: 1px solid #999;
        }
      }
    }
  }
}
</style>
