<template>
  <div class="MenuFormTreeNested">
    <draggable
      class="list"
      :class="list && list.length ? '' : 'list-empty'"
      v-bind="getDragOptions(menuId)"
      :list="list"
      @start="start"
      @add="menuMove"
      @update="menuMove"
    >
      <div class="item-container" v-for="item in list" :key="item.id">
        <div class="item" :class="item.id == activeMenuId ? 'item-active' : ''" >
          <div class="item-left">
            <div class="item-left-icon" :class="item.$status == 1 ? 'item-left-icon-close' : 'item-left-icon-open'" v-if="item.menuType == 1" @click="statusChange(item)"></div>
            <div class="item-left-empty" v-else></div>
            <div class="item-left-name">{{ item.name }}</div>
          </div>
          <!-- <div class="item-right-icon"></div> -->
        </div>
        <menu-form-tree-nested
          v-show="item.$status == 1"
          :setTempData="setTempData"
          :resetData="resetData"
          :setActiveMenuId="setActiveMenuId"
          :activeMenuId="activeMenuId"
          :menuId="item.id"
          :list="item.childMenus"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { menuMoveURL } from './api'

export default {
  name: 'MenuFormTreeNested',
  props: {
    setTempData: Function,
    resetData: Function,
    setActiveMenuId: Function,
    activeMenuId: [String, Number],
    menuId: [String, Number],
    list: Array
  },
  components: {
    Draggable
  },
  methods: {
    canPut (menuId) {
      if (menuId == 0) {
        return false
      }
      return true
    },
    canPull (menuId) {
      if (menuId == 0) {
        return false
      }
      return true
    },
    getDragOptions (menuId) {
      return {
        handle:'.item-left-name',
        group: {
          name: 'menu',
          put: this.canPut(menuId),
          pull: this.canPull(menuId)
        },
        ghostClass: 'item-container-ghost'
      }
    },
    start () {
      this.setTempData()
    },
    async menuMove (evt) {
      const id = this.list[evt.newIndex].id
      const pId = this.menuId
      const site = evt.newIndex
      const regionId = this.$route.query.tenantId
      let url = ''
      if(regionId!=undefined){
        url = `${menuMoveURL}?id=${id}&pId=${pId}&site=${site}&regionId=${regionId}`
      } else {
        url = `${menuMoveURL}?id=${id}&pId=${pId}&site=${site}`
      }
      const loading = this.$loading({
        target: '.views',
        text: '正在处理...，请不要离开该页面（^_^）',
        background: 'rgba(0, 0, 0, 0.3)',
        customClass: 'loading-menu-form-tree'
      })
      const { status, data } = await this.$axios.post(url)
      if (status === 100) {
        this.setActiveMenuId(id)
        this.$message.success('操作成功')
      } else {
        this.resetData()
      }
      loading.close()
    },
    resetStatus (list) {
      if (list && list.length) {
        list.forEach(item => {
          item.$status = 0
          this.resetStatus(item.childMenus)
        })
      }
    },
    statusChange (item) {
      if (item.$status === 0) {
        item.$status = 1
      } else {
        this.resetStatus(item.childMenus)
        item.$status = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MenuFormTreeNested {
  .list {
    min-width: 212px;
    min-height: 43px;
    white-space: nowrap;
    .item-container {
      display: flex;
      align-items: flex-start;
      width: 188px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100%;
        padding: 12px 0 12px 24px;
        .item-left {
          display: flex;
          align-items: center;
          width: 100%;
          .item-left-icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .item-left-icon-open {
            background-image: url("./images/btn_open.png");
          }
          .item-left-icon-close {
            background-image: url("./images/btn_close.png");
          }
          .item-left-empty {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          .item-left-name {
            // flex: 1;
            cursor: grab;
          }
        }
        // .item-right-icon {
        //   display: inline-block;
        //   width: 8px;
        //   height: 14px;
        //   margin-left: 8px;
        //   background-image: url("./images/btn_move.png");
        //   background-repeat: no-repeat;
        //   background-size: contain;
        //   cursor: grab;
        // }
      }
      .item-active {
        background: rgb(27, 140, 242, 0.8);
      }
    }
    .item-container-ghost {
      opacity: 0.5;
    }
  }
  .list-empty {
    background-image: url("./images/empty.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
