var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuFormTreeNested" },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "list",
            class: _vm.list && _vm.list.length ? "" : "list-empty",
            attrs: { list: _vm.list },
            on: { start: _vm.start, add: _vm.menuMove, update: _vm.menuMove },
          },
          "draggable",
          _vm.getDragOptions(_vm.menuId),
          false
        ),
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "item-container" },
            [
              _c(
                "div",
                {
                  staticClass: "item",
                  class: item.id == _vm.activeMenuId ? "item-active" : "",
                },
                [
                  _c("div", { staticClass: "item-left" }, [
                    item.menuType == 1
                      ? _c("div", {
                          staticClass: "item-left-icon",
                          class:
                            item.$status == 1
                              ? "item-left-icon-close"
                              : "item-left-icon-open",
                          on: {
                            click: function ($event) {
                              return _vm.statusChange(item)
                            },
                          },
                        })
                      : _c("div", { staticClass: "item-left-empty" }),
                    _c("div", { staticClass: "item-left-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]),
                ]
              ),
              _c("menu-form-tree-nested", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.$status == 1,
                    expression: "item.$status == 1",
                  },
                ],
                attrs: {
                  setTempData: _vm.setTempData,
                  resetData: _vm.resetData,
                  setActiveMenuId: _vm.setActiveMenuId,
                  activeMenuId: _vm.activeMenuId,
                  menuId: item.id,
                  list: item.childMenus,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }